#fingerprint {
	margin: auto;
	max-width: 60%;
	opacity: 80%;
}

#fingerprintBox {
	animation: fadeIn 9s;
	-webkit-animation: fadeIn 9s;
	-moz-animation: fadeIn 9s;
	-o-animation: fadeIn 9s;
	-ms-animation: fadeIn 9s;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
