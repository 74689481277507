::-webkit-scrollbar {
	width: 4px;
	height: 10px;
}
::-webkit-scrollbar-track {
	background: #121212;
}
::-webkit-scrollbar-thumb {
	background: #0e959499;
}
