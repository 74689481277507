.mdNav a {
	position: relative;
	display: block;
	color: #0e9594;
	transition: 0.5s;
	z-index: 1;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-top: 2px solid #0e9594;
		border-bottom: 2px solid #0e9594;
		transform: scaleY(2);
		opacity: 0;
		transition: 0.3s;
	}
	&:after {
		content: "";
		position: absolute;
		top: 2px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #0e9594;
		transform: scale(0);
		opacity: 0;
		transition: 0.3s;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			transform: scaleY(1);
			opacity: 1;
		}
		&:after {
			transform: scaleY(1);
			opacity: 1;
		}
	}
}
